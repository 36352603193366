import { isDevelopment } from "@biblioteksentralen/utils";
import { ReactNode } from "react";
import { useMount } from "react-use";

export const SetupMswServer = (props: { children: ReactNode }) => {
  useMount(async () => {
    if (isDevelopment()) {
      // Lazy load to avoid importing msw in production
      const { setupRandomlyFailRequests } = await import("./randomlyFailRequests");
      await setupRandomlyFailRequests();
    }
  });

  return <>{props.children}</>;
};
