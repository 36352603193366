"use client";

import { BiblioteksentralenProvider } from "@biblioteksentralen/react";
import { storeFeatureFlags } from "@libry-content/common";
import { FrontendLocale } from "@libry-content/localization";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/next";
// eslint-disable-next-line no-restricted-imports
import { NextIntlClientProvider } from "next-intl";
import { ReactNode, useState } from "react";
import Testenvironmentbanner from "../../components/TestEnvironmentBanner";
import { EditInSanityContextProvider } from "../../components/editInSanity/EditInSanityContext";
import { FrokostseminarProvider } from "../../components/frokostseminar/Frokostseminar";
import { StudioSOMEPreview } from "../../components/preview/StudioSOMEPreview";
import { PreviewContextProvider } from "../../components/preview/previewContext";
import { commonNextIntlConfig, IntlMessages } from "../../i18n";
import { PlausibleSetup } from "../../utils/analytics/Plausible";
import { customTheme } from "../../utils/customTheme";
import { useLogDevInfo } from "../../utils/logDevInfo";
import { SetupMswServer } from "./chaosEngineering/SetupMswServer";

export const Providers = (props: { children: ReactNode; messages?: IntlMessages; locale: FrontendLocale }) => {
  // storeFeatureFlags should run only once but before other useEffect hooks; useMount and useEffect are too late.
  // useLayoutEffect works but gives an error when running on server, so we use an initial value function with useState.
  useState(() => storeFeatureFlags());

  useLogDevInfo();

  return (
    <>
      <SetupMswServer>
        <NextIntlClientProvider locale={props.locale} messages={props.messages} {...commonNextIntlConfig}>
          <PreviewContextProvider>
            <BiblioteksentralenProvider customTheme={customTheme}>
              <FrokostseminarProvider>
                <EditInSanityContextProvider>
                  <PlausibleSetup />
                  <Testenvironmentbanner />
                  {props.children}
                  <StudioSOMEPreview />
                </EditInSanityContextProvider>
              </FrokostseminarProvider>
            </BiblioteksentralenProvider>
          </PreviewContextProvider>
        </NextIntlClientProvider>
      </SetupMswServer>
      <Analytics />
      <SpeedInsights sampleRate={0.05} />
    </>
  );
};
